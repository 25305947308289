import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import Note from '../components/Note';
import Code from '../components/code';
export const _frontmatter = {
  "title": "My Favorite Gear",
  "date": "2020-01-08",
  "promo": "flexbox",
  "description": "Some of the best gear for a developer's work",
  "color": "#d6592c"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I'm a gear junkie. Call me materialistic, but well-made things really do make me happy. Here's my all-time favorite gear so far — I hope you enjoy!`}</p>
    <p>{`Note: I was able to line up affiliate links for a handful of these, so if you buy using my links I may earn a bit o' cash that I'll put towards more free games like `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{`. You don't pay any extra and in some cases even get a discount.`}</p>
    <p>{`I'll keep this list up to date as new/better things come along!`}</p>
    <h2>{`Favorite Monitor Arm`}</h2>
    <p>{`I'm a big fan of having a dual, vertical monitor setup. You get tons of screen real estate without having to crane your neck side to side. I searched far and wide for a heavy duty monitor arm that could support the weight of two big monitors. The `}<a parentName="p" {...{
        "href": "https://www.amazon.com/gp/product/B004NJKWVQ/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=geddski-20&creative=9325&linkCode=as2&creativeASIN=B004NJKWVQ&linkId=4ecfe37a8ff8682a4fcb6d0f3646d187"
      }}>{`Innovative 7500 Dual Arm`}</a>{` is a dream come true. I've had it for over two years now and it hasn't failed me once. Having both monitors attached to a single arm means you can move the entire thing forward/back/up/down as you please. It's fantastic.`}</p>
    <p>{`I like putting code on the bottom monitor, browser on the top monitor. Here's the desk where the magic happens:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/monitor-arm.jpg",
        "alt": "monitor arm"
      }}></img></p>
    <h2>{`Favorite Desk Lights`}</h2>
    <p>{`I got some `}<a parentName="p" {...{
        "href": "https://www.amazon.com/gp/product/B07ZRYZ13R/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=geddski-20&creative=9325&linkCode=as2&creativeASIN=B07ZRYZ13R&linkId=121c85db93257eda934ca2e4e70a59cf"
      }}>{`Philips Hue light strips`}</a>{` recently and stuck them to the back edge of my desk. It has completely transformed and upgraded my office space. I can set the colors to whatever vibrant mood I'm in. Or a nice bright white for working. It's hard to describe the effect, but it just makes my working environment feel welcoming and ready for me to be productive in.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/favorite-lights.jpg",
        "alt": "favorite desk lights"
      }}></img></p>
    <h2>{`Favorite Keyboard`}</h2>
    <p><a parentName="p" {...{
        "href": "https://ergodox-ez.com/"
      }}>{`The Ergodox EZ`}</a>{` is an incredible, split mechanical keyboard. I've had about a dozen different keyboards for coding and this one's my favorite. It's the only keyboard (that I know of) that lets you swap out the key switches. I started with Cherry Blues switches but found the clicky click a bit too distracting. Switched out to the softer Gateron Brown switches and they're just right.`}</p>
    <p>{`You can control its LED atmosphere lights and customize all the keys with their `}<a parentName="p" {...{
        "href": "https://configure.ergodox-ez.com/"
      }}>{`web config tool`}</a>{`. Here's my current setup with the (optional) blank keycaps:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/ergodox.jpg",
        "alt": "ergodox keyboard"
      }}></img></p>
    <h2>{`Favorite Mouse`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.amazon.com/gp/product/B07S395RWD/ref=as_li_tl?ie=UTF8&tag=geddski-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B07S395RWD&linkId=03e711e841e1b3238d5f86a7b8e62e19"
      }}>{`MX Master 3`}</a>{` is an excellent mouse. I've had the previous two versions of this mouse as well, and each model upgrade has added a little bit of polish. The mouse is ergonomic, comfortable, tracks well on my slick bamboo desk surface, has decently big side buttons for back/forward, and an overall quality/industrial feel.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/mx-master-3.jpg",
        "alt": "mx master 3 mouse"
      }}></img></p>
    <h2>{`Favorite Backpack`}</h2>
    <p>{`After a ton of research and a few returns, I went with the `}<a parentName="p" {...{
        "href": "https://www.boundarysupply.com/geddski"
      }}>{`Boundary Supply Prima System`}</a>{` backpack. They're a small company not far from where I live here in the valley, and they make a really good bag. I've used mine heavily for over a year and the rugged thing is still good as new. It's made from a water-resistent canvas-like material that reminds me of camping as a kid. It has tons of pockets for all my stuff: laptop, iPad, chargers, headphones, notebook etc. You can get all sorts of accessories for it like a camera/drone insert. It's comfy on the ol' back, and is compact enough to be good for taking my rig on flights and to conferences.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/boundary-bag.jpg",
        "alt": "boundary supply backpack"
      }}></img></p>
    <h2>{`Favorite Wallet`}</h2>
    <p>{`Another local company makes this minimalist `}<a parentName="p" {...{
        "href": "https://www.amazon.com/gp/product/B00TVBOL6S/ref=as_li_tl?ie=UTF8&tag=geddski-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B00TVBOL6S&linkId=79e74e8489886a758665556ff9abfd36"
      }}>{`Nomatic Wallet`}</a>{` and it's perfect. Super slim, fits just a handful of things but that's all I need. I got one for my dad to see if I could get him to switch away from his ultra thick 80's style leather wallet, but no luck.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/nomatic-wallet.jpg",
        "alt": "nomatic wallet"
      }}></img></p>
    <h2>{`Favorite Notebook`}</h2>
    <p>{`I've been tryout out `}<a parentName="p" {...{
        "href": "https://www.amazon.com/gp/product/0525533338/ref=as_li_tl?ie=UTF8&tag=geddski-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=0525533338&linkId=087ad5070e15cb9e8a2025fab6e55d27"
      }}>{`bullet journaling`}</a>{`. I like the idea of evolving my own custom system for staying on top of everything. I'm just using the `}<a parentName="p" {...{
        "href": "https://bulletjournal.com/products/notebook"
      }}>{`official notebook`}</a>{` and it's quality.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/bullet-journal-notebook.jpg",
        "alt": "bullet journal notebook"
      }}></img></p>
    <h2>{`Favorite Pen`}</h2>
    <p>{`I'm a big fan of these ultra thin (0.38) `}<a parentName="p" {...{
        "href": "https://www.amazon.com/dp/B00Y1TXDO6/ref=cm_sw_r_tw_dp_PSVZDA815JN6G80CDXKW"
      }}>{`Uniball Signo RT1`}</a>{` pens. They let you create small, neat writings which is perfect for journaling and `}<a parentName="p" {...{
        "href": "/post/learn-to-draw/"
      }}>{`drawing`}</a>{`. They dry fast, don't bleed through the page, and are waterproof once dry.`}</p>
    <p>{`I also like these fine tip (0.05) `}<a parentName="p" {...{
        "href": "https://www.amazon.com/gp/product/B00O58E130/ref=as_li_tl?ie=UTF8&tag=geddski-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B00O58E130&linkId=a9a6486e1b954195c9225e767f5a97db"
      }}>{`pigment liners`}</a>{`, but they don't seem to last very long.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/uniball-signo.jpg",
        "alt": "favorite pen"
      }}></img></p>
    <h2>{`Favorite Gaming Monitor`}</h2>
    <p>{`Since I build (education) games for a living, I figure it's important to play plenty of other games for `}<em parentName="p">{`research`}</em>{` purposes ;) I wanted a better gaming monitor that wasn't crazy expensive, and ultimately picked the `}<a parentName="p" {...{
        "href": "https://www.amazon.com/gp/product/B078TTXLW5/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=geddski-20&creative=9325&linkCode=as2&creativeASIN=B078TTXLW5&linkId=bdf6e2366e4b7ea0144766aecac74b68"
      }}>{`LG 32GK850G-B`}</a>{`. The high 144hz refresh rate makes a huge difference for games — everything is super smooth. Having g-sync is a nice, I no longer get screen tearing when I play Overwatch. This monitor is a VA panel instead of the more common IPS panel, so blacks are `}<em parentName="p">{`very black`}</em>{`. Which is exactly what you want if you play games with lots of shadows (e.g. `}<a parentName="p" {...{
        "href": "https://www.ea.com/games/starwars/jedi-fallen-order"
      }}>{`Jedi Fallen Order`}</a>{`) or space (e.g. `}<a parentName="p" {...{
        "href": "https://robertsspaceindustries.com/"
      }}>{`Star Citizen`}</a>{`) like I do. This monitor doesn't have some of the newer tech like HDR or 4k, but you really don't need those for games yet. Like all tech, 4k and HDR are crazy expensive when new but will come down in price in a couple years. `}</p>
    <p>{`Space games, and this monitor, are Pippin approved.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/pippin-citizen.jpg",
        "alt": "star citizen with Pippin"
      }}></img></p>
    <p>{`I'm always trying new things and will keep this list up to date as I find more awesome gear. If you've got something you love that you think I'd enjoy please let me know!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      